<template>
  <div v-if="hasModule('transportation')">
    <b-card
        no-body
        class="card-statistics"
    >
      <b-card-header>
        <b-card-title>Overview</b-card-title>
        <b-card-text class="mr-25 mb-0">
          <!--        August 4th 2022, 7:09 AM-->
        </b-card-text>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
          >
            <b-media no-body>
              <b-media-aside

                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    variant="light-success"
                >
                  <feather-icon
                      size="24"
                      icon="TruckIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ stats.vehicles }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Vehicles
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
          >
            <b-media no-body>
              <b-media-aside
                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    variant="light-danger"
                >
                  <feather-icon
                      size="24"
                      icon="UsersIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ stats.drivers }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Drivers
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
          >
            <b-media no-body>
              <b-media-aside
                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    variant="light-primary"
                >
                  <feather-icon
                      size="24"
                      icon="MapPinIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ stats.pickup_points}}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Pickup Points
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
          >
            <b-media no-body>
              <b-media-aside

                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    variant="light-info"
                >
                  <feather-icon
                      size="24"
                      icon="TruckIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ stats.completed_trips }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Completed Trips
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card
        no-body
        class="card-statistics"
    >
      <b-card-header>
        <b-card-title>Students On Transportation</b-card-title>
        <b-card-text class="mr-25 mb-0">
          <!--        August 4th 2022, 7:09 AM-->
        </b-card-text>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
          >
            <b-media no-body>
              <b-media-aside

                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    variant="light-success"
                >
                  <feather-icon
                      size="24"
                      icon="UsersIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0" v-if="stats.requests">
                  {{ stats.requests.busin }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Bus In
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
          >
            <b-media no-body>
              <b-media-aside
                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    variant="light-danger"
                >
                  <feather-icon
                      size="24"
                      icon="UsersIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0" v-if="stats.requests">
                  {{ stats.requests.busout}}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Bus Out
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
          >
            <b-media no-body>
              <b-media-aside
                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    variant="light-primary"
                >
                  <feather-icon
                      size="24"
                      icon="UsersIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0" v-if="stats.requests">
                  {{ stats.requests.both }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Bus In & Out
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
          >
            <b-media no-body>
              <b-media-aside

                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    variant="light-info"
                >
                  <feather-icon
                      size="24"
                      icon="UsersIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ stats.pending_requests }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Pending Requests
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card
        no-body
        class="card-statistics"
    >
      <b-card-header>
        <b-card-title>Bus Attendance (Today vs Previous Day)</b-card-title>
        <b-card-text class="mr-25 mb-0">
          <!--        August 4th 2022, 7:09 AM-->
        </b-card-text>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
          >
            <b-media no-body>
              <b-media-aside

                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    variant="light-success"
                >
                  <feather-icon
                      size="24"
                      icon="UsersIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ stats.attendance.present }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Present Today
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
          >
            <b-media no-body>
              <b-media-aside
                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    variant="light-danger"
                >
                  <feather-icon
                      size="24"
                      icon="UsersIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ stats.attendance.absent }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Absent Today
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
          >
            <b-media no-body>
              <b-media-aside
                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    variant="light-primary"
                >
                  <feather-icon
                      size="24"
                      icon="UsersIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ stats.attendance_prev.present }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Previously Present
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
          >
            <b-media no-body>
              <b-media-aside

                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    variant="light-info"
                >
                  <feather-icon
                      size="24"
                      icon="UsersIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ stats.attendance_prev.absent }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Previously Absent
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-overlay
        :show="loading"
        :variant="darkMode?'dark':'white'"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="md"
    >
    <b-card
        no-body
        class="card-statistics"
    >
      <b-card-header>
<!--        <b-card-title>Revenue</b-card-title>-->
        <b-card-text class="mr-25 mb-0">
          <p style="font-size: 19px"><b>Filter Revenue</b></p>
          <div class="d-flex">
            <lenon-select :options="academicYears" v-model="selectedAcademicYearId" label-name="name" value-name="id" placeholder="Academic Year"
                          class="mr-1"
            />
            <lenon-select :options="terms" v-model="selectedTermId" label-name="name" value-name="id" placeholder="Term" class="mr-1"/>
            <lenon-select :options="classes" v-model="selectedClassId" label-name="name" value-name="id" placeholder="Class" class="mr-1"/>
            <lenon-select :options="tripTypes" v-model="selectedTripType" label-name="label" value-name="id" placeholder="Trip Type"
                          class="mr-1"
            />
            <lenon-select :options="pickupPoints" v-model="selectedPickupPointId" label-name="label" value-name="id" placeholder="Pickup Point"
                          class="mr-1"
            />
            <lenon-date-picker v-model="selectedDate" :show-label="false" label="Trip Date"/>
          </div>
        </b-card-text>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
              md="4"
              sm="6"
              class="mb-2 mb-md-0"
          >
            <b-media no-body>
              <b-media-aside

                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    variant="light-primary"
                >
                  <feather-icon
                      size="24"
                      icon="PlusIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{currencySymbol}}{{ rev.expected | currency }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Total Expected
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
              md="4"
              sm="6"
              class="mb-2 mb-md-0"
          >
            <b-media no-body>
              <b-media-aside
                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    variant="light-success"
                >
                  <feather-icon
                      size="24"
                      icon="PlusIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{currencySymbol}}{{ rev.collected | currency}}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Total Collected
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
              md="4"
              sm="6"
              class="mb-2 mb-md-0"
          >
            <b-media no-body>
              <b-media-aside
                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    variant="light-danger"
                >
                  <feather-icon
                      size="24"
                      icon="MinusIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                 {{currencySymbol}}{{ rev.arrears | currency}}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Total Arrears
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    </b-overlay>
  </div>
  <div v-else>
    <b-card>
      <h4 class="text-center">
        You do not have access to Transportation Module
      </h4>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody,BOverlay, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'
import LenonSelect from '@/lenon/components/LenonSelect'
import LenonDatePicker from '@/lenon/components/LenonDatePicker'
import useAppConfig from '@core/app-config/useAppConfig'

const { skin } = useAppConfig()
export default {
  components: {
    LenonDatePicker,
    LenonSelect,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BOverlay
  },
  name: 'Index.vue',
  data() {
    return {
      selectedAcademicYearId: null,
      selectedTermId: null,
      selectedClassId: null,
      selectedTripType: null,
      selectedPickupPointId: null,
      selectedDate: null,
      loading: false,
      stats:{
        request:{
          busin: 0,
          busout: 0,
          both: 0,
        },
        attendance:{},
        attendance_prev:{},
      },
      rev:{
        arrears: 0,
        collected: 0,
        expected: 0,
      },
      tripTypes:[
        {label:"Bus In",id:"busin"},
        {label:"Bus Out",id:"busout"},
        // {label:"Both",id:"both"},
      ]
    }
  },
  watch:{
    monitorOption(val){
      // if(this.selectedAcademicYearId){
        this.getRevenue();
      // }
    }
  },
  computed:{
    currencySymbol() {
      return process.env.VUE_APP_CURRENCY
    },
    darkMode() {
      return skin.value === 'dark'
    },
    monitorOption(){
      return (+this.selectedAcademicYearId)+
          (+this.selectedTermId)+
          (+this.selectedClassId)+
          (+this.selectedPickupPointId)+
          (+this.selectedTripType)+
          (+this.selectedDate)
    },
    academicYears(){
      return this.$store.getters["termsClasses/academicYears"]
    },
    terms(){
      return this.$store.getters["termsClasses/terms"]
    },
    classes(){
      return this.$store.getters["termsClasses/allClasses"]
    },
    pickupPoints(){
      return this.$store.getters["pickupPoint/pickupPoints"]
    }
  },
  methods:{
    hasModule(module) {
      const modules = this.$store.getters['auth/schoolModules']
      return modules.includes(module)
    },
    getDashboardStats(){
      this.$http.get("transportation/dashboard-stats").then((res)=>{
        this.stats = res.data
      })
    },
    getRevenue(){
      this.loading = true
      this.$http.get(`transportation/transportation-revenue?academic_year_id=${this.selectedAcademicYearId}&term_id=${this.selectedTermId}&class_id=${this.selectedClassId}&trip_type=${this.selectedTripType}&pickup_point_id=${this.selectedPickupPointId}&payment_date=${this.selectedDate}`).then((res)=>{
        this.rev = res.data
      }).finally(()=>{
        this.loading = false
      })
    }
  },
  mounted() {
    this.getDashboardStats()
  }
}
</script>

<style scoped>

</style>
